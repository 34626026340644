.telex-container {
  padding: 0 30px;
}

.telex-medrec-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.global-telex-tabs {
  width: auto;
  margin: 2rem;
  overflow-y: hidden;
  overflow-x: hidden;
}

.add-telex-container {
  margin: 3rem 5rem 0;
  max-width: 80%;
  display: flex;
}

.telex-add-form {
  flex: 1;
  margin: 0px 3rem;
}

.telex-add-form>* {
  margin-top: 1rem;
}

.padding-modal {
  padding: 10px;
}

.rainbow-checkbox-group_checkbox .rainbow-checkbox-group_checkbox-faux {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border: 1px solid #01b6f5;
  border-radius: 0.4rem;
  background: white;
  margin-right: 0.75rem;
  -webkit-transition: border 0.1s linear, background-color 0.1s linear;
  transition: border 0.1s linear, background-color 0.1s linear;
  cursor: pointer;
}

.margify-checkbox {
  margin: 1rem;
}

.flex-column {
  flex-direction: column;
}

.contacts-container {
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  max-height: 41vh;
  overflow-y: auto;
}

.react-rainbow-admin-pages_container {
  padding: 16px 40px;
}

.react-rainbow-admin-pages_body {
  display: flex;
  flex-wrap: wrap;
}

.react-rainbow-admin-pages_card {
  width: 200px;
  font-size: 16px;
  font-weight: 500;
  color: #061c3f;
}

.react-rainbow-admin-pages_card-image {
  padding: 1rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-rainbow-admin-pages_anchor {
  margin-top: 24px;
  margin-right: 24px;
}

.react-rainbow-admin-pages_anchor:hover {
  text-decoration: none;
}

.react-rainbow-admin-pages_anchor:last-child {
  margin-right: 0;
}

.telex-notif-indicator { 
  color: white;
  background-color: #ea4243;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50px;
  text-align: center;
  /* position: absolute; */
  line-height: 1.5;
  right: 0.5rem;
  padding: 0;
  margin: 0;
}


@media (max-width: 800px) {
  .react-rainbow-admin-pages_container {
    padding: 16px;
  }

  .react-rainbow-admin-pages_anchor,
  .react-rainbow-admin-pages_card {
    margin-right: 8px;
  }

  .react-rainbow-admin-pages_card-image {
    height: 8rem;
  }
}

@media (max-width: 600px) {

  .react-rainbow-admin-pages_anchor,
  .react-rainbow-admin-pages_card {
    width: 100%;
    margin-right: 0;
  }
}

.add-patient-button {
  margin: 20px 0px;
}

.react-rainbow-admin-users_header-container {
  padding: 16px 40px 0;
  margin-bottom: 16px;
}

.react-rainbow-admin-users_header {
  margin-top: 14px;
}

.react-rainbow-admin-users_cards-container {
  padding: 16px 40px 24px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.react-rainbow-admin-users_card {
  width: 48%;
  padding-bottom: 16px;
}

.react-rainbow-admin-users_chart {
  margin: 0 20px;
}

.react-rainbow-admin-users_card-title {
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #a4a7b5;
  text-transform: uppercase;
  margin-bottom: 8px;
  text-align: center;
}

.react-rainbow-admin-users_tab-set {
  padding-left: 40px;
  background-color: #f4f6f9;
}

.react-rainbow-admin-users_current-status {
  display: flex;
  align-items: center;
}

.react-rainbow-admin-users_current-status_value {
  margin-left: 8px;
}

.react-rainbow-admin-users_table-edit-icon {
  border: 1px solid #e3e5ed;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-rainbow-admin-users_badge {
  background-color: #1de9b6;
}

.react-rainbow-admin-users_user-id-cell-container {
  display: flex;
  align-items: center;
}

.rainbow-table_cell-content {
  border: 1px solid transparent;

  color: #576574;

  font-size: 0.875rem;

  height: 5em;

  line-height: 40px;

  padding: 0 0.5rem;

  max-width: 100%;

  overflow: hidden;

  text-overflow: ellipsis;

  white-space: nowrap;
}

.react-rainbow-admin-users_user-id-cell {
  margin-left: 8px;
  font-weight: 400;
}

.react-rainbow-admin-users_user-id-cell-container:hover {
  color: #01b6f5;
}

@media (max-width: 800px) {
  .react-rainbow-admin-users_header-container {
    padding: 16px;
    margin-bottom: 16px;
  }

  .react-rainbow-admin-users_cards-container {
    flex-direction: column;
  }

  .react-rainbow-admin-users_card {
    width: 100%;
    margin-bottom: 16px;
  }

  .react-rainbow-admin-users_tab-set {
    padding-left: 24px;
  }


  .sc-jwKygS {
    overflow: auto;
  }

  .contacts-container {
    display: block;
  }

  .sc-iELTvK {
    width: 100% !important;
  }

  .jVrEsm {
    max-width: none;
    margin: auto 10%;
  }

  .telex-add-form {
    margin: auto;
  }

  .add-telex-container {
    margin: 3rem auto 0;
    margin-bottom: 3rem;
    width: auto;
    display: flex;
  }

}