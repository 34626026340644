.original-image-class {
    min-height: 30vh;
    min-width: 30vw;
    height: auto
}



.rotate-0 .image-gallery-image {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}

.rotate-90 .image-gallery-image {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
}

.rotate-180 .image-gallery-image {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.rotate-270 .image-gallery-image {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}


.image-gallery-slide {
    background-color: inherit;
    position: absolute;
    height: auto;
    top: inherit;
    left: inherit;
}