.react-rainbow-admin-tile {
    width: 100%;
    flex-wrap: nowrap;
    overflow: hidden;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: 0;
    position: relative;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    display: flex;
    flex: 1 1 auto;
}

.break-word {
    word-break: break-word;
}

.react-rainbow-admin-tile:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-tile_title {
    letter-spacing: 0.3px;
    color: #3e3e3c;
}

.react-rainbow-admin-tile_title--inverse {
    color: #e3e5ed;
}

.react-rainbow-admin-tile_content {
    font-size: 26px;
    line-height: 1.07;
    letter-spacing: 1.8px;
    color:#45b6f5;
    
}

.react-rainbow-admin-tile_content--inverse {
    color: #FFF;
}
.w-100 {
    width:100%;
}
.tile-icon-v2
{
    color: #45b6f5;
    align-self: center;
    font-style: normal;
    font-weight: 300;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    padding-left: 10px;
}

@media (max-width: 800px) {
    .react-rainbow-admin-tile:not(:last-child) {
        margin: 0 0 8px 0;
    }
}
