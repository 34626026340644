.content-container {
    display: flex;
    justify-content: center;
    margin-top: 4em;
    flex: 1;
    margin-bottom: 2.5em;
    align-items: center;
}

.buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;

}

.original-image-class {
    min-height: 30vh;
    min-width: 30vw;
    height: auto
}


.to-right {
    transform: rotate(90deg) !important;
    display: block;
    overflow: auto !important
}


.marged-button {
    margin: 0px 0.6rem;
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 1px;
}

.hRyptZ {
    overflow: hidden !important;
}

.jbUGrP {
    overflow: hidden !important;
}

.rainbow-tabset_inner-container {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    align-self: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-justify-content: center;
}

