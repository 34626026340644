.notification-indicator {
    color: #45b6f5;
    position: absolute;
}

.notif-container {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: flex-end;
}


.iwmuLl {
    position: absolute;
    z-index: 2000;
    left: 50%;
    float: left;
    max-width: 21rem;
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
    border: solid 1px #e3e5ed;
    border-radius: 0.875rem;
    padding: 0.5rem 0;
    font-size: 0.75rem;
    background: white;
    box-shadow: 0 2px 4px 0 #e3e5ed;
    top: 100%;
    display: block;
    opacity: 1;
    -webkit-transition: opacity 0.1s linear, visibility 0.1s linear;
    transition: opacity 0.1s linear, visibility 0.1s linear;
    visibility: visible;
    min-width: 20rem;
    left: auto;
    right: 0;
}

.sc-kGXeez {
    overflow: auto;
    height: auto;
    max-height: 80vh;
    width: auto;
}

.react-rainbow-admin_header {
    top: 0;
    left: 0;
    height: 68px;
    width: 100vw;
    z-index: 10000;
    border-bottom: 1px solid #f4f6f9;
}

.break-word {
    white-space: pre-line;
}

.react-rainbow-admin_header-logo {
    width: 130px;
    height: 40px;
}

.react-rainbow-admin_header-search {
    width: 280px;
}

.react-rainbow-admin_header-actions {
    justify-content: flex-end;
    flex-grow: 1;
}

.react-rainbow-admin_header--notification-icon-container {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #e3e5ed;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.react-rainbow-admin_header--notification-icon {
    height: 12px;
}

.react-rainbow-admin_header-hamburger-button {
    display: none;
}

.react-rainbow-admin_header-github-icon {
    width: 32px;
    height: 32px;
    fill: #576574;
}

.react-rainbow-admin_header-github-icon:hover {
    fill: #061c3f;
}

@media (max-width: 600px) {
    .react-rainbow-admin_header {
        height: auto;
        padding: 16px 16px 0 16px;
        align-items: flex-start;
    }

    .react-rainbow-admin_header-search {
        margin: 0;
        flex-shrink: 0;
        position: absolute;
        bottom: 12px;
        width: calc(100% - 80px);
    }

    .react-rainbow-admin_header-actions {
        align-items: flex-start;
    }

    .react-rainbow-admin_header-hamburger-button {
        display: inherit;
        height: 40px;
        width: 40px;
        margin-left: 16px;
    }

    .react-rainbow-admin_header-button-notification {
        right: 4px;
    }

    .react-rainbow-admin_header-github-icon {
        margin-right: 16px;
    }
}

.header-v2 {
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 99999 !important;
    padding-left: 1rem !important;
}

.katomi-logo-v2 {
    height: 42px;
    margin-left: 5px;
}

/*Profile picture style span */
#root>div>div>div:nth-child(2)>header>section>div>button>span {
    height: 1.5em;
    width: 1.5em;
}

.react-rainbow-admin_header-actions button {
    color: #8e8e95;
    transition: all 0.3s linear;
}

:focus {
    box-shadow: none !important;
}

.notification-icon-v2 {
    font-size: 1.5em;
}

#root>div>div>div:nth-child(2)>header>section>div:nth-child(1)>div:nth-child(2)>div>div>ul>li>span {
    text-transform: capitalize;
    font-family: jura;
}