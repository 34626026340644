.signup-container {
    display: flex;
    justify-content: center;
    background:url('/assets/images/loginBG.jpg');
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow:auto ;
    flex-wrap: wrap;
}


.signup-card {
    margin: 8rem auto;
    max-width: 70vw;
}

.signup-form {
    margin: 4% auto;
    opacity: 0.9;
    flex-grow: 0;
}

.image-container {
    border:2px dashed #a4a7b5;
    border-radius:2rem;
    transition:all 0.1s linear;
    width:100%;
    line-height:2.5rem;
    flex:1 1 auto;
    margin-top:0.75rem;
    height:10rem;
}

.image-input {
   cursor:pointer;
   border-radius:100px;
   font-size:1rem;
    margin-top: 1rem;
   text-decoration:none;
    line-height:2.375rem;
   align-self: center;
   background-color:#009acf;
   border-color:#009acf;
   color:white;
   border:1px solid #01b6f5;
   padding:0 1rem;
   text-align:center;
   vertical-align:middle;
   transition:border 0.15s linear; 

}

.image {
    width:100%;
    height: 100%;
    border-radius: 2rem
}

.react-rainbow-admin-forms_card{
    width: 40%;
    flex-wrap: wrap;
    position: absolute;
    margin-top: 4%;
    margin-bottom: 4%;
    opacity: 0.9;
}

.react-rainbow-admin-forms_card:not(:last-child) {
    margin-right: 48px;
}

.react-rainbow-admin-forms_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-forms_header h1 {
    font-size: 1.2rem;
    color: #061c3f;
}

.react-rainbow-admin-forms_header p {
    font-weight: 300;
    color: #576574;
    line-height: 1.71;
}

.react-rainbow-admin-forms_header p:nth-child(2){
    font-weight: normal;
    color: #01b6f5;
    margin-left: 3px;
    cursor: pointer;
}

.logo_img {
    width: 199px;
    height: 80px;
    margin-bottom: 15px;
}


.rainbow-rainbow-forms_forgot {
    text-align: center;
    color: #01b6f5;
    font-size: 1rem;
}



@media (max-width: 800px){
    .react-rainbow-admin-forms_container {
        padding: 16px;
    }

    .react-rainbow-admin-forms_section {
        flex-direction: column;
        padding-top: 0;
    }

    .rainbow-rainbow-forms_button {
        width: 100%;

        display:flex;
        justify-content: center;
    }

    .react-rainbow-admin-forms_section:last-child  {
        width: 100%;
    }

    .react-rainbow-admin-forms_card {
        margin-top: 13px;
    }
    
   

    .rainbow-rainbow-forms_inputs-field {
        margin: 0 0 18px 0 !important;
    }

    .signup-card {
        max-width: none;
    }
}
