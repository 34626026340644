.add-index-c-button {
    height: max-content;
    align-self: end;
    margin: auto;
}

.wrapflex {
    flex-wrap: wrap;
}

.card-cpurpose {
    opacity: 1;
    padding: 1.5rem;
}

.submit-buttons-cpurpose {
    justify-content: space-around;
}

.AHPRx:not(button) {
    pointer-events: inherit;
}

.index-c-list {
    border: 1px solid #a4a7b570;
    flex-direction: column;
    border-radius: 5px;
    margin: 1rem;
    padding: 1rem;
    max-width: 50%;
}

/* .datsAf {
    border: 1px solid #a4a7b570;
}
.fmuUrp {
    border: 1px solid #a4a7b570;
}

.eLWbwz {
    border: 1px solid #a4a7b570;
} */

.criteria-ship {
    background-color: #d0ebf470;
}

/* .sc-kTUwUJ {
    align-self: auto;
} */

/* .dCKwTP {
    align-self: auto;
} */

.index-c-title {
    letter-spacing: 0.3px;
    font-size: 1.075rem;
    color: #576574;
}

.index-c-elems {
    display: flex;
    flex-wrap: wrap;
}

.index-c-card {
    max-width: 70%;
    margin-top: 4rem;
    min-width: 65%;
}