.email-verif-container {
    display: flex;
    justify-content: center;
    background: url('/assets/images/loginBG.jpg');
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: unset;
}


.react-rainbow-admin-forms_card {
    width: 40%;
    flex-wrap: wrap;
    position: absolute;
    opacity: 0.9;
}

.text-card {
    margin: 20px;
    padding: 30px;
}