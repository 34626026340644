.agenda-container {
  padding: 0 30px;
  margin: 2em auto;
  width: 80%
}

.agenda-add-button {
  margin: 1em;
}

.agenda-buttons-container {
  display: flex;
  flex-wrap: wrap-reverse;
}

.telecav-notice-container {
  padding: 1rem;
  margin: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #a4a7b570;
  border-radius: 5px;
}

.telecav-notice-elem {
  display: list-item;
  color: #009acf;
  margin-left: 1rem;
  margin-bottom: 5px;
}

.telecav-notice-elem>p {
  color: #3e3e3c;
}

.telecav-notice-title {
  font-size: 1.5rem;
  align-self: center;
  margin-bottom: 1rem;
  color: #009acf;
}

.telecav-notice-content {
  display: flex;
  flex-direction: column;
}

.jgSBRJ {
  text-transform: none;
}

.jwKFx {
  text-transform: none;
}

.rbc-event {
  border: none;
  box-shadow: none;
  margin: 0;
  padding: 2px 5px;
  background-color: #fff0;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
  /* background-color: #18b6f5; */
}

.rbc-event-label {
  color: #3e3e3c;
}

.rbc-event .rbc-selected {
  background-color: inherit;
}



.rbc-event.rbc-selected {
  background-color: inherit;
}

.rbc-event {
  height: auto !important;
}

.rbc-day-slot .rbc-event-content {
  line-height: 1.5;
}

@media (max-width: 800px) {

  .agenda-container {
    max-width: 170vw;
    width: max-content;
  }

  .agenda-buttons-container {
    flex-wrap: wrap;
  }
}