.logo-rotate {
        animation: rotation 5s infinite linear;
}

.no-internet-container {
    display: flex;
    justify-content: center;
    margin-top: 7rem;
}


@keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }