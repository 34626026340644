.login-container-form {
    display: flex;
    justify-content: center;
    background: url('/assets/images/loginBG.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
}

.login-card {
    flex: 1 1;
    max-width: 45vw;
    min-height: 40vh;
    align-self: center;
    padding: 30px;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: 0;
    position: relative;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #ffffffd6;
    background-clip: border-box;
    border-radius: .25rem;
}

.logo-img-auth {
    width: 199px;
    height: 58px;
    margin-bottom: 15px;
}

.login-field {
    margin: 2rem;
    flex: 1;
}




.react-rainbow-admin-forms_header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.react-rainbow-admin-forms_header h1 {
    font-size: 1.2rem;
    color: #061c3f;
}

.react-rainbow-admin-forms_header p {
    font-weight: 300;
    color: #576574;
    line-height: 1.71;
}

.react-rainbow-admin-forms_header p:nth-child(2) {
    font-weight: normal;
    color: #01b6f5;
    margin-left: 3px;
    cursor: pointer;
}


.rainbow-rainbow-forms_forgot {
    text-align: center;
    color: #01b6f5;
    font-size: 0.85rem;
}
.forgot-v2 {
    font-size: 0.85rem!important;
}

.login-icon-v2 {
    padding-right : 10px;
    padding-left : 10px;
}
.forgot-icon-v2 {
    padding-right : 10px;
    padding-left : 10px;
}


@media (max-width: 800px) {
    .login-card {
        flex: 1;
        max-width: 100vw;
        min-height: 40vh;
        align-self: center;
        padding: 30px;
    }
    

    .react-rainbow-admin-forms_container {
        padding: 16px;
    }

    .react-rainbow-admin-forms_section {
        flex-direction: column;
        padding-top: 0;
    }

    .rainbow-rainbow-forms_button {
        width: 100%;
    }

    .react-rainbow-admin-forms_section:last-child {
        width: 100%;
    }

    .react-rainbow-admin-forms_card {
        margin-top: 24px;
    }

  

    .rainbow-rainbow-forms_inputs-field {
        margin: 0 0 18px 0 !important;
    }
}