.react-rainbow-admin-app_sidebar-container {
    position: fixed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 68px);
    margin-top: 68px;
    background-color: #fff;
    z-index: 99998;
    padding-bottom: 14px;
    padding-top: 14px;
}

.react-rainbow-admin-app_sidebar {
    width: 6rem;
    height: 100%;
    border-right: 1px solid #f4f6f9;
    overflow-y: auto;
    flex-grow: 1;
}

.react-rainbow-admin-app_router-container {
    padding-top: 68px;
    padding-left: 88px;
    background-color: #f4f6f9;
    min-height: 100vh;
}

.react-rainbow-admin-app_sidebar-item {
    margin-bottom: 16px;
    /* height: 5rem; */
    /* padding: 0px 5px; */
}

.react-rainbow-admin-app_sidebar-back-button-container {
    display: none;
}

@media (max-width: 600px) {
    .react-rainbow-admin-app_sidebar-container {
        /* height: 100vh; */
        margin-top: 3rem;
        transform: translateX(0%);
        transition: transform 230ms linear, opacity 230ms linear;
    }

    .react-rainbow-admin-app_router-container {
        padding-top: 116px;
        padding-left: 0;
    }

    .react-rainbow-admin-app_sidebar-container--sidebar-hidden {
        transform: translateX(-100%);
        transition: transform 250ms linear, opacity 250ms linear;
    }

    .react-rainbow-admin-app_sidebar-back-button-container {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-grow: 2;
        align-items: flex-end;
        padding-top: 12px;
    }

    .react-rainbow-admin-app_sidebar-back-button-icon {
        color: #000;
    }

    .react-rainbow-admin-app_backdrop {
        display: unset;
        width: 100vw;
        height: 100vh;
        background-color: rgba(82, 95, 127, 0.4);
        /* position: fixed; */
        z-index: 99999;
        top: 0;
        left: 0;
    }

    .app-container-2 {
        width: min-content;
    }

    .hRyptZ {
        width: auto;
        justify-content: flex-start;
    }

}

.is-jura {
    font-family: 'Jura', sans-serif !important;
}

.is-josefin {
    font-family: 'Josefin Slab', serif !important;
}

.is-katomi-blue {
    background-color: #167ce9 !important;
}

.is-boxed {
    -webkit-box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
}

.is-text {
    color: #7782aa !important;
    font-size: 1.25em !important;
}

.is-subtitle {
    letter-spacing: 0.1em !important;
    font-size: 1.25em !important;
    color: #7782aab8 !important;
}

.app-container-v2 {
    background-color: #fff;

}

.item-v2 {
    margin-bottom: 0;
    border-bottom: 1px solid #80808026;
    transition: all 0.15s linear;
}

:hover {
    opacity: 1;
}

.item-v2:focus-within {
    opacity: 1;
}

.item-icon-v2 {
    color: #0aa7ff;
}

.react-rainbow-admin-app_sidebar-item.item-v2>button>span {
    color: #3e3e3c;
    font-size: 0.655rem;
    letter-spacing: 0.2px;
}

.item-v2>button>div {
    background-color: transparent !important;
    z-index: -99999;
}

.sidebar-v2 {
    background-color: #ffffff8a;
    padding-top: 0px;
}

.p-top_huge {
    padding-top: 3rem;
}


.section-add-button {
    border-radius: inherit;
}


.variant-brand {
    background-color: inherit;
    color: #009acf;
    border: none;
}

.variant-brand:hover {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-brand:active {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-brand:focus {
    /* border: 1px solid #009acf; */
    background-color: #d0ebf4a3;
    color: #009acf;
}

.variant-success {
    background-color: inherit;
    color: #1ad1a3;
    border: none;
}

.variant-success:hover {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-success:active {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-success:focus {
    /* border: 1px solid #1ad1a3; */
    background-color: #c1f0e482;
    color: #1ad1a3;
}

.variant-destructive {
    background-color: inherit;
    color: #ea4243;
    border: none;
}

.variant-destructive:hover {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}

.variant-destructive:active {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}

.variant-destructive:focus {
    /* border: 1px solid #ea4243; */
    background-color: #f4c9c975;
    color: #ea4243;
}



.jbUGrP {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    padding: 0 0.75rem;
}

.cqyuXG {
    border: 1px solid transparent;
    color: #576574;
    font-size: 0.875rem;
    height: auto;
    max-height: 6.5rem;
    line-height: 50px;
    padding: 0 0.5rem;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    white-space: normal;
}

.ccPWrI {
    overflow-y: hidden;
}

section>header {
    font-family: 'Jura', sans-serif !important;
}

.hCRPWM {
    overflow: auto;

    text-overflow: ellipsis;

    white-space: normal;
}