.jgSBRJ {
    text-transform: none;
}


.padding-modal {
    padding: 10px;
}


.rainbow-radio-group_inner-container {
    display: flex;
    flex-direction: column;
}


.starting-today-card {
    padding: 1rem;
    font-size: 1rem;
    border: none;
    border-radius: .25rem;
}

.starting-today-card > div > header > h2 > a > span {
    font-weight: 600;
    letter-spacing: 1px;
}

.starting-today-card > div > header > h2 > a  {
    text-decoration: none;
    cursor: default;
}

.starting-today-card > div {
margin-top: 0;
}
