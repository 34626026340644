.check-media-modal {
    padding: 1rem;
    width: auto;
}

.check-media-title {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
    color: #009acf;
    font-size: 1.4rem;
}

.check-media-status-container {
    margin: 1rem 3rem;
    font-size: 1rem;
    margin-bottom: 2rem;
}

.check-media-status-item {
    margin-bottom: 1rem;
}

.check-media-status-item>* {
    margin-left: 10px;
}

.check-media-success {
    color: #1ad1a3;
}

.check-media-error {
    color: #ea4243;
}


.telec-start-modal {
    min-width: 80vw;
    min-height: 100vh;
    display: flex;
    flex: 1;
}

.telec-content-container {
    display: flex;
    height: 100vh;
    margin: 1rem auto;
}


.call-container {
    width: 70%;
}

.caller {
    height: 30vh;
    display: block;
    margin: 1rem auto;
}

.receiver {
    width: 100%;
    height: 60vh;
}


.chat-container {
    border-left: 2px solid #e6e9ef;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    flex: 1;
    margin-left: 0.375rem;
}

.messages-cont {
    max-height: 80%;
    min-height: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0;
    margin-top: 2rem;
    word-break: break-all;
    padding: 0 0.5rem;
}

.telec-msg-input {
    width: 100%;
}

.non-connected-remote {
    display: flex;
    justify-content: center;
    font-size: 2rem;
    padding: 2em;
}


.telec-icons-container {
    position: absolute;
    bottom: 4px;
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 1.5rem;
}