.accordion-container {
  min-width: min-content;
  margin: auto;
  max-width: max-content;
  margin-bottom: 1em;
}

.antecedents-cont {
  max-width: 80vw;
}

.select-margin {
  margin: 2rem 1rem;
  margin-bottom: 1rem;
}

.flex-grow {
  flex: 1;
}

.TAUF {
  text-transform: none;
}

.rainbow-radio-group_inner-container {
  display: flex;
}

.rainbow-radio-group_radio .rainbow-radio-group_radio-faux {
  width: 20px;
  height: 20px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  border: 1px solid #01b6f5;
  border-radius: 12rem;
  background-color: white;
  transition: border 0.1s linear, background-color 0.1s linear;
  margin: auto 15px;
}

.button-add {
  margin: auto;
  margin-top: 0.4em;
  width: 30%;
}

.react-rainbow-admin-forms_card:not(:last-child) {
  margin-right: 48px;
}

.react-rainbow-admin-forms_header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.react-rainbow-admin-forms_header h1 {
  font-size: 1.2rem;
  color: #061c3f;
}

.react-rainbow-admin-forms_header p {
  font-weight: 300;
  color: #576574;
  line-height: 1.71;
}

.react-rainbow-admin-forms_header p:nth-child(2) {
  font-weight: normal;
  color: #01b6f5;
  margin-left: 3px;
  cursor: pointer;
}

.react-rainbow-admin-forms_logo {
  width: 140px;
  height: 50px;
  margin-bottom: 15px;
}

.rainbow-rainbow-forms_inputs-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
}

.rainbow-rainbow-forms_forgot {
  text-align: center;
  color: #01b6f5;
  font-size: 1rem;
}

@media (max-width: 800px) {
  .react-rainbow-admin-forms_container {
    padding: 16px;
  }

  .react-rainbow-admin-forms_section {
    flex-direction: column;
    padding-top: 0;
  }

  .rainbow-rainbow-forms_button {
    width: 100%;

    display: flex;
    justify-content: center;
  }

  .react-rainbow-admin-forms_section:last-child {
    width: 100%;
  }

  .react-rainbow-admin-forms_card {
    margin-top: 13px;
  }

  .rainbow-rainbow-forms_inputs-container>.rainbow-flex {
    flex-direction: column;
  }

  .rainbow-rainbow-forms_inputs-field {
    margin: 0 0 18px 0 !important;
  }

  .antecedents-cont {
    max-width: none;
  }
}