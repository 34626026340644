
.react-rainbow-admin-dashboard_view-port {
    padding: 16px 40px 24px 40px;
}

.react-rainbow-admin-dashboard_card {
    width: 100%;
    flex-wrap: wrap;
}

.react-rainbow-admin-dashboard_card:not(:last-child) {
    margin-right: 24px;
}

.react-rainbow-admin-dashboard_card--content {
    font-size: 28px;
    line-height: 1.07;
    letter-spacing: 1.8px;
}

.react-rainbow-admin-dashboard_badge--success {
    background-color: #1de9b6 !important;
    align-self: flex-start;
}

.react-rainbow-admin-dashboard_badge--error {
    background-color: #fe4849 !important;
    align-self: flex-start;
}

.react-rainbow-admin-dashboard_chart-title {
    letter-spacing: 0.2px;
    text-transform: uppercase;
}

.react-rainbow-admin-dashboard_avatar {
    flex-shrink: 0;
}

.react-rainbow-admin-dashboard_divider {
    border-bottom: solid 1px #e3e5ed;
}

.react-rainbow-admin-dashboard_list-card--footer-link {
    font-size: 13px;
}

.react-rainbow-admin-dashboard_list-card--footer-link:hover,
.react-rainbow-admin-dashboard_list-card--footer-link:focus,
.react-rainbow-admin-dashboard_list-card--footer-link:active,
.react-rainbow-admin-dashboard_list-card--footer-link:visited {
    color: #009acf;
}

.react-rainbow-admin-dashboard_card-users--user {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: #fe4849;
    margin-right: 4px;
    margin-bottom: 4px;
}

.react-rainbow-admin-dashboard_card-users--user-active {
    background-color: #1de9b6;
}

.react-rainbow-admin-dashboard_card-message--message-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.react-rainbow-admin-dashboard_tile-icon {
    height: 37px;
    width: 37px;
}

@media (max-width: 900px) {
    .react-rainbow-admin-dashboard_view-port {
        padding: 16px 16px 24px 16px;
    }

    .react-rainbow-admin-dashboard_section {
        flex-direction: column;
    }

    .react-rainbow-admin-dashboard_card:not(:last-child) {
        margin: 0 0 8px 0;
    }
}

.charts-card-v2{
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: 0;
    position: relative;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
}
.list-card-v2{
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: 0;
    position: relative;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    
}
.list-card-v2 .react-rainbow-admin-users_tab-content {
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    padding: 0;
    background-color: white;
    border: 0;
    border-radius: 0;
}
.react-rainbow-admin-dashboard_view-port > div > section:nth-child(3) > article:nth-child(2) > div {
    padding-top:3rem;
}
.view-icon-v2 {
    color: #45b6f5;
    padding: 5px;
    font-size: 1.5em;
}